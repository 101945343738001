import { gsap } from "gsap";
import { findAll,find} from "../utils/dom.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function  gsapMentoria (){


	console.log('mentoria script')
	const Home = find("[js-page='mentorias']")
	if (!Home) return
	gsap.registerPlugin(ScrollTrigger);

	 const mq = window.matchMedia('(max-width: 1024px)').matches;
		if(mq) return
		gsap.to("#sticky-teste", {
				scrollTrigger: {
				trigger: "#sticky-teste",
				start: "top top+=200px", // Quando o topo do #principal-footer alcançar o fundo da viewport
				end: "bottom",
				// markers: true ,
				pin: true,
				pinSpacing: true,

			},
		})
	// gsap.from("#principal-footer", {
	// 	scrollTrigger: {
	// 		trigger: "#principal-footer",
	// 		start: "top bottom", // Quando o topo do #principal-footer alcançar o fundo da viewport
	// 		// markers: true ,
	// 		once: false

	// 	},
	// 	// opacity: 0 
	// 	// Move o elemento 200px no eixo Y antes de iniciar a animação
		
	// });
}