import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

const showToastify = (message, opts) => {
	Toastify({
		duration: 3000,
		position: "center",
		// style: { background: "linear-gradient(to right, var(--primary-pure), var(--primary-light))" },
		style: { background: "#5cb85c" },
		text: message,
		...opts
	}).showToast();
}

export default showToastify