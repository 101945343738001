import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Palestra = find("[js-page='palestra']")
	if (!Palestra) return

	console.log('Page palestra');
    

    const hideForm = document.querySelector(".hideForm");
    if (!hideForm) return;
  
    const hideButtonForm = document.querySelectorAll(".hideButtonForm");
    hideButtonForm.forEach((e) => {


      e.addEventListener('click', () => {
        hideForm.classList.toggle("hidden");
      });
    });


		const swiperExplore = new Swiper('.swiper-temas', {
				slidesPerView: 'auto',
				spaceBetween: 16,
				slideToClickedSlide: true,
				centeredSlides:true,
				navigation: {
						nextEl: '.nav-custom-wrapper .next-slide',
						prevEl: '.nav-custom-wrapper .prev-slide'
				},
				breakpoints: {
						767: {
								spaceBetween: 32,
								centeredSlides:false,
						}
				},
		});

const btnsExplore = document.querySelectorAll('[js-click-change]');
const videosHiden = document.querySelectorAll('[data-video-id]');
const textHidden = document.querySelectorAll('[data-text-id]');

// Função para atualizar o conteúdo com base no índice ativo
function updateContent(activeIndex) {
    btnsExplore.forEach((btn, index) => {
        if (index === activeIndex) {
            btn.classList.add('active');
            const id = btn.id;

            // Exibe o vídeo e texto correspondente
            videosHiden.forEach(video => {
                video.style.display = video.dataset.videoId === id ? 'block' : 'none';
            });

            textHidden.forEach(text => {
                text.style.display = text.dataset.textId === id ? 'block' : 'none';
            });
        } else {
            btn.classList.remove('active');
        }
    });
}

// Adiciona evento ao Swiper para sincronizar com o slide ativo
swiperExplore.on('slideChange', () => {
    const activeIndex = swiperExplore.activeIndex;
    updateContent(activeIndex);
});

// Adiciona evento de clique aos botões
btnsExplore.forEach((btn, index) => {
    btn.addEventListener('click', () => {
        swiperExplore.slideTo(index); // Move para o slide correspondente
        updateContent(index);        // Atualiza o conteúdo com base no índice
    });
});

// Inicializa o conteúdo com o primeiro slide ativo
updateContent(swiperExplore.activeIndex);
		

    // const swiper_video_explore = new Swiper(".swiper_video_explore", {
    //   freeMode: true,
    //   watchSlidesProgress: true,
    //   slidesPerView: 1,
    //   spaceBetween: 16,
    //   loop: true,
    //   speed: 1000,
    
    //   autoplay: {
    //     delay:40000,
    //     pauseOnMouseEnter: false,
    //     disableOnInteraction: false,
    //   },
    
    //   on: {
    //     transitionStart: function() {
    //       var activeIndex = this.realIndex;
    //       var nextIndex = activeIndex + 1;
    
          
    //       if (nextIndex >= this.slides.length) {
    //         nextIndex = 0;
    //       }
    
    //       var nextSlide = this.slides[nextIndex];
    //       var nextSlideVideo = nextSlide?.querySelector('video');
    //       if (nextSlideVideo) {
    //         nextSlideVideo.play();
    //       }
    //     },
    
    //     slideChange: function() {
    //       var previousIndex = this.previousIndex;
    //       var previousSlide = this.slides[previousIndex];
    //       var previousSlideVideo = previousSlide?.querySelector('video');
    //       if (previousSlideVideo) {
    //         previousSlideVideo.pause();
    //       }
    
    //       var activeIndex = this.realIndex;
    //       var activeSlide = this.slides[activeIndex];
    //       var activeSlideVideo = activeSlide?.querySelector('video');
    //       if (activeSlideVideo) {
    //         activeSlideVideo.play();
    //       }
    //     },
    //   }
    // });
    
    // const swiperPalestraGlobal = new Swiper(".swiper-palestra-global", {
    //   slidesPerView: "auto",
    //   loop: true,
    //   centeredSlides: true,
    
    //   breakpoints: {
    //     220: {
    //       spaceBetween: 5
    //     },
    //     1024: {
    //       spaceBetween: 16
    //     },
    //   },
    // });
    
    // swiper_video_explore.controller.control = swiperPalestraGlobal;
    // swiperPalestraGlobal.controller.control = swiper_video_explore;
    
    
    



		const breakpoint = window.matchMedia( '(min-width:1024px)' );
	// keep track of swiper instances to destroy later
	let mySwiper;
	let mySwiper2;
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	const breakpointChecker = function() {
		// if larger viewport and multi-row layout needed
		if ( breakpoint.matches === true ) {
				// clean up old instances and inline styles when available
				if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
				if ( mySwiper2 !== undefined ) mySwiper2.destroy( true, true );
				// or/and do nothing
				return;
		// else if a small viewport and single column layout needed
		} else if ( breakpoint.matches === false ) {
				// fire small viewport version of swiper
				return enableSwiper();
		}
	};

	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
		const enableSwiper = function() {
			mySwiper = new Swiper ('#tab1.swiper_beneficio_palestra', {
				slidesPerView: 1.1,
				spaceBetween: 16,
					pagination: {
						el: "#tab1.swiper_beneficio_palestra .swiper-pagination-palestra",
						clickable: true,
					},
			
					navigation: {
						nextEl: "#tab1.swiper_beneficio_palestra .button-next",
						prevEl: "#tab1.swiper_beneficio_palestra .button-prev",
					},
			});
			mySwiper2 = new Swiper ('#tab2.swiper_beneficio_palestra', {
				slidesPerView: 1.1,
				spaceBetween: 16,
					pagination: {
						el: "#tab2.swiper_beneficio_palestra .swiper-pagination-palestra",
						clickable: true,
					},
			
					navigation: {
						nextEl: "#tab2.swiper_beneficio_palestra .button-next",
						prevEl: "#tab2.swiper_beneficio_palestra .button-prev",
					},
			});
		};
		//////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////
		// keep an eye on viewport size changes
		breakpoint.addListener(breakpointChecker);
		// kickstart
		breakpointChecker();





  

}