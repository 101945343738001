import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='mentorias']")
	if (!Home) return

	console.log('Page mentorias');

  const swiper_mentorias = new Swiper(".swiper_mentorias", {
	slidesPerView: "auto",
	spaceBetween: 16,
		pagination: {
			el: ".swiper-pagination-mentorias",
			clickable: true,
		},

		navigation: {
			nextEl: ".swiper-button-mentorias-next",
			prevEl: ".swiper-button-mentorias-prev",
		},

		breakpoints: {	
			1024: {
				slidesPerView: 1,
				spaceBetween: 0,
			},	
	
		},	
  });


}