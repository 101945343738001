import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	console.log('Page home');

	const swiperGlobal = new Swiper(".swiper-global", {

		slidesPerView: 'auto',
		loop: true,
		allowTouchMove: false,
		alowtouch: false,
		speed: 8000,

		breakpoints: {
			220: {
				spaceBetween: 5
			},
			770: {
				spaceBetween: 32
			},
			1024: {
				spaceBetween: 64
			},

		},


		autoplay: {
			delay: 1,
			pauseOnMouseEnter: true,
			disableOnInteraction: false
		},
	});

	var swiper = new Swiper(".mySwiper", {
		freeMode: true,
		watchSlidesProgress: true,
		navigation: {
			nextEl: ".button-next",
			prevEl: ".button-prev",
		},
		breakpoints: {
			220: {
				slidesPerView: 2,
				spaceBetween: 5,
			},

			1024: {

				slidesPerView: 4,
				spaceBetween: 16,

			},

		},


	});

	var swiper2 = new Swiper(".mySwiper2", {
		// spaceBetween: 10,
		speed: 1000,
		autoplay: {
			delay: 40000,
			pauseOnMouseEnter: false,
			disableOnInteraction: false
		},

		thumbs: {
			swiper: swiper,
		},
	});

	var swiper_ecosistema = new Swiper(".swiper_ecosistema", {
		freeMode: true,
		watchSlidesProgress: true,
		navigation: {
			nextEl: ".swiper_ecosistema .swiper-button-next",
			prevEl: ".swiper_ecosistema .swiper-button-prev",
		},
		breakpoints: {
			220: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			},

			1024: {
				slidesPerView: 5,
				spaceBetween: 16,
			},

		},


	});

	var swiper_ecosistema_banner = new Swiper(".swiper_ecosistema_banner", {
		spaceBetween: 10,

		thumbs: {
			swiper: swiper_ecosistema,
		},
	});

	var swiper_empresas = new Swiper(".swiper_empresas", {
		slidesPerView: 'auto',
		loop: true,
		allowTouchMove: false,
		alowtouch: false,
		speed: 3000,

		breakpoints: {
			220: {
				slidesPerView: 5,
				spaceBetween: 16,
			},

			1024: {
				slidesPerView: 8,
				spaceBetween: 16,
			},

		},


		autoplay: {
			delay: 1,
			pauseOnMouseEnter: true,
			disableOnInteraction: false
		},


	});

	var swiper_aprenda_banner = new Swiper(".swiper_aprenda_banner", {
		freeMode: true,
		watchSlidesProgress: true,
		navigation: {
			nextEl: ".swiper_aprenda_banner .button-next",
			prevEl: ".swiper_aprenda_banner .button-prev",
		},

		pagination: {
			el: ".swiper_aprenda_banner .swiper-pagination-aprender",
			clickable: true,
			type: "bullets"
		},
		breakpoints: {
			220: {
				spaceBetween: 16,
			},

			1024: {
				slidesPerView: 'auto',
				spaceBetween: 24,

			},

		},
	});

	let swiperItemBanner = new Swiper(".swiperItemBanner", {
		direction: 'vertical',
		freeMode: true,
		watchSlidesProgress: true,
		slidesPerView: 3,
		spaceBetween: 0,
	});

	let swiperBanner = new Swiper(".swiperBanner", {
		// spaceBetween: 10,
		speed: 1000,
		slidesPerView: 1,
		autoplay: {
			delay: 15000,
			pauseOnMouseEnter: false,
			disableOnInteraction: false
		},

		thumbs: {
			swiper: swiperItemBanner,
		},
	});

	const hideForm = document.querySelector(".hideForm");
	if (!hideForm) return;

	const hideButtonForm = document.querySelectorAll(".hideButtonForm");
	hideButtonForm.forEach((e) => {


		e.addEventListener('click', () => {
			hideForm.classList.toggle("hidden");
		});
	});

	const lazy_load_video = () => {
		document.addEventListener("DOMContentLoaded", function () {
			let videoSection = document.querySelector("#destaque_gustavo");
			let videos = document.querySelectorAll(".lazy-video");

			let observer = new IntersectionObserver((entries, obs) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						// Quando a seção entra na tela, carregamos os vídeos
						videos.forEach(video => {
							let source = video.querySelector("source"); // Pega o <source> dentro do <video>
							if (source && source.getAttribute("src")) {
								// Se o src já estiver configurado, não precisa fazer nada
								video.load(); // Carrega o vídeo
							} else if (source && source.getAttribute("data-src")) {
								// Se estiver usando o data-src, defina o src e carregue o vídeo
								source.src = source.getAttribute("data-src"); // Defina o src do <source> a partir do data-src
								video.load(); // Carrega o vídeo
							}
						});
						obs.unobserve(videoSection); // Para de observar após carregar os vídeos
					}
				});
			}, { threshold: 0.5 }); // Ativa quando 50% da seção está visível

			observer.observe(videoSection);
		});
	}

	lazy_load_video()

}