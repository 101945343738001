import axios from 'axios'
import showToastify from '../utils/toastify';

export default function palestrasForm() {
	const contatoFormSubmit = document.querySelector('#form-palestra')
	contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
		event.preventDefault();
		disabledBtn()


		// ["id", "nome", "email", "contato", "cargo", "assunto", "data", "mensagem"],

		const form_data = {
			nome: document.querySelector('#form-palestra input[name=nome]').value,
			email: document.querySelector('#form-palestra input[name=email]').value,
			contato: document.querySelector('#form-palestra input[name=contato]').value,
			cargo: document.querySelector('#form-palestra input[name=cargo]').value,
			//assunto: document.querySelector('#form-palestra select[name=assunto]').value,
			// mentoria_interesse: document.querySelector('#form-palestra select[name=mentoria_interesse]').value,
			data: document.querySelector('#form-palestra input[name=data]').value
		}
		console.log(form_data);
		const url = "/formulario_palestra/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));
	});

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector("#form-palestra button[type=submit]")

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.innerHTML = 'Enviando ...'
		btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.innerHTML = 'Enviar mensagem'
		btnSubmit.classList.remove("disabled")
	}
}