import axios from 'axios'
import showToastify from '../utils/toastify';

export default function newsletterForm(){
	const contatoFormSubmit = document.querySelector('#form-newsletter')
	contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
			event.preventDefault();
			disabledBtn()

		


			// ["id", "nome", "email", "contato", "cargo", "assunto", "data", "mensagem"],

			const form_data = {
					
					email: document.querySelector('#form-newsletter input[name=email]').value,
				
			}
			console.log(form_data);
			const url = "/newsletter/"
			axios.post(url, form_data)
					.then((response) => showMessage(response))
					.catch((response) => showMessage(response));
	});

	function showMessage(response) {
			const message = response.data ? response.data.message : response.message
			showToastify(message)
			contatoFormSubmit.reset()
			setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector("#form-newsletter button[type=submit]")

	function disabledBtn() {
			btnSubmit.disabled = true
			// btnSubmit.innerHTML = 'Enviando ...'
			btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
			btnSubmit.disabled = false
			// btnSubmit.innerHTML = 'Enviar mensagem' 
			btnSubmit.classList.remove("disabled")
	}


}