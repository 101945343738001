import Swiper from "swiper"
import { find,findAll } from "utils/dom"

export default function () {
	const Home = find("[js-page='treinamentos']")
	if (!Home) return

	console.log('Page treinamentos');



	const itemsFront = findAll('[js-show-back]')
	const itemsBack = findAll('[js-show-front]')

	itemsFront?.forEach(i => {
			i.addEventListener('click', (e) =>{
				const pai = e.target.closest('.card-flip')
				pai.classList.add('active-card')
			})
	});
	itemsBack?.forEach(i => {
			i.addEventListener('click', (e) =>{
				const pai = e.target.closest('.card-flip')
				pai.classList.remove('active-card')
			})
	});
	// breakpoint where swiper will be destroyed
	// and switches to a dual-column layout
	const breakpoint = window.matchMedia( '(min-width:768px)' );
	// keep track of swiper instances to destroy later
	let mySwiper;
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	const breakpointChecker = function() {
		// if larger viewport and multi-row layout needed
		if ( breakpoint.matches === true ) {
				// clean up old instances and inline styles when available
				if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
				// or/and do nothing
				return;
		// else if a small viewport and single column layout needed
		} else if ( breakpoint.matches === false ) {
				// fire small viewport version of swiper
				return enableSwiper();
		}
	};

	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////
		const enableSwiper = function() {
			mySwiper = new Swiper ('.swiper_treinamento', {
				slidesPerView: 1.1,
				spaceBetween: 16,
					pagination: {
						el: ".swiper-pagination-treinamentos",
						clickable: true,
					},
			
					navigation: {
						nextEl: ".swiper-button-treinamentos-next",
						prevEl: ".swiper-button-treinamentos-prev",
					},
			});
		};
		//////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////
		// keep an eye on viewport size changes
		breakpoint.addListener(breakpointChecker);
		// kickstart
		breakpointChecker();

}