import { gsap } from "gsap";
import { height } from "../utils/dom";

export default function animateFrom(elem, direction) {
	direction = direction || 1;
	let delay = 0;

	let x = 0,
		y = 0,
		scale = 1,
		skewYY = 0,
		ease = "power3",
		opacity = 1;

	let duration = 1;
	const att = elem.getAttribute('data-anima');
	const valorTransicao = elem.getAttribute("data-value");

	//direção e fade
	switch (att) {
		case "left":
			x = valorTransicao || -100;
			y = 0;
			break;
		case "right":
			x = valorTransicao || 100;
			y = 0;
			break;
		case "down":
			y = valorTransicao || height(elem);
			break;
		case "up":
			y = -(valorTransicao || height(elem));
			break;
		case "scale":
			scale = 0.6;
			break;
		case "scale-fade":
			opacity = 0;
			scale = 0.6;
			break;
		case "opacity":
			opacity = 0;
			break;
		case "fade":
			opacity = 0;
			break;
		case "fade-up":
			opacity = 0;
			y = -(valorTransicao || height(elem));
			break;
		case "fade-down":
			opacity = 0;
			y = valorTransicao || height(elem);
			break;
		case "fade-left":
			opacity = 0;
			x = valorTransicao || -100;
			break;
		case "fade-right":
			opacity = 0;
			x = valorTransicao || 100;
			break;
		case "fade-up-right":
			opacity = 0;
			x = valorTransicao || 100;
			y = -(valorTransicao || height(elem));
			break;
		case "fade-up-left":
			opacity = 0;
			x = -(valorTransicao || 100);
			y = -(valorTransicao || height(elem));
			break;
		case "fade-down-right":
			opacity = 0;
			x = valorTransicao || 100;
			y = valorTransicao || height(elem);
			break;
		case "fade-down-left":
			opacity = 0;
			x = -(valorTransicao || 100);
			y = valorTransicao || height(elem);
			break;
		default:
			break;
	}

	if (elem.hasAttribute('skewy')) {
		skewYY = 5;
	}

	//delay
	if (elem.dataset.delay) {
		delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`;
	}
	if (elem.dataset.duration) {
		duration = `${elem.dataset.duration}`;
	}
	if (elem.dataset.ease) {
		ease = `${elem.dataset.ease}`;
	}

	gsap.timeline()
		.fromTo(elem, {
			x: x,
			y: y,
			scale: scale,
			skewY: skewYY,
			autoAlpha: opacity
		}, {
			ease: ease,
			duration: duration,
			x: 0,
			y: 0,
			autoAlpha: 1,
			delay: delay,
			scale: 1,
			overwrite: "auto",
			skewY: 0,
			onComplete: () => elem.classList.add("animou")
		}).timeScale(1);
}
