import Swiper from "swiper"
import { find } from "utils/dom"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function () {
  const gustavo = find("[js-page='gustavo-borges']")
  if (!gustavo) return

  console.log('Page Gustavo Borges');

  gsap.registerPlugin(ScrollTrigger);
  let tl = gsap.timeline({ defaults: { opacity: 1, ease: "back", } });


  const box = document.querySelector('#grid-single-inspiracoes')
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      tl.to("#grid-single-inspiracoes  .to-fill-timeline", {

        scrollTrigger: {
          trigger: ".to-fill-timeline",
          start: "center 50%",
          //markers:true,
          scrub: 3,
          end: () => `+=${entry.contentRect.height + 225}`,
        },
        height: '100%',
        duration: 2,
        ease: "linear",
      })
    }
  });

  // Observa o elemento
  resizeObserver.observe(box);

  const changeHeader = () => {
    const headerClickTl = document.querySelectorAll('[data-gustavo-borges]')

    headerClickTl.forEach(item => {
      item.addEventListener('click', (e) => {
        e.target.click()

        console.log(item.dataset.gustavoBorges);
        if (item.dataset.gustavoBorges == 'empreendedor') {
          tl.pause(0).restart()
          headerClickTl.forEach(item1 => {
            const h = item1.querySelector('h2')
            h.classList.remove('border-color-ouro')
            h.classList.add('border-transparent')
          })
          const showTextAtleta = document.querySelectorAll('.text-atleta')
          const showTextEmpreendedor = document.querySelectorAll('.text-empreendedor')
          showTextAtleta.forEach(i => { i.classList.add('hidden') })
          showTextEmpreendedor.forEach(i => { i.classList.remove('hidden') })

          const h = item.querySelector('h2')
          h.classList.remove('border-transparent')
          h.classList.add('border-[#8FC6F8]')
        }

        if (item.dataset.gustavoBorges == 'atleta') {
          tl.pause(0).restart()
          headerClickTl.forEach(item1 => {
            const h = item1.querySelector('h2')
            h.classList.remove('border-[#8FC6F8]')
            h.classList.add('border-transparent')
          })

          const showTextAtleta = document.querySelectorAll('.text-atleta')
          const showTextEmpreendedor = document.querySelectorAll('.text-empreendedor')
          showTextAtleta.forEach(i => { i.classList.remove('hidden') })
          showTextEmpreendedor.forEach(i => { i.classList.add('hidden') })

          const h = item.querySelector('h2')
          h.classList.remove('border-transparent')
          h.classList.add('border-color-ouro')
        }
      })
    })
  }

  changeHeader()

  const swiper_jornada = new Swiper(".swiper_jornada", {
    watchSlidesProgress: true,
    spaceBetween: 24,
    slidesPerView: 'auto',

    navigation: {
      nextEl: ".swiper_jornada .button-next",
      prevEl: ".swiper_jornada .button-prev",
    },

    pagination: {
      el: ".swiper_jornada .swiper-pagination-jornada",
      clickable: true,
    }
  });

  const swiper_jornada_2 = new Swiper(".swiper_jornada_2", {
    watchSlidesProgress: true,
    spaceBetween: 24,
    slidesPerView: 'auto',
    autoHeight: true,

    navigation: {
      nextEl: ".swiper_jornada_2 .button-next",
      prevEl: ".swiper_jornada_2 .button-prev",
    },

    pagination: {
      el: ".swiper_jornada_2 .swiper-pagination-jornada-2",
      clickable: true,
    }
  });
}